/*.user-records tbody tr td {*/
/*    padding: 15px !important;*/
/*}*/

.user-records td:first-child a {
    padding-left: 1.5rem;;
}

.user-records td a {
    padding: 10px;
}

.user-records td a:hover {
    text-decoration: none !important;
}

.user-records .roles a {
    text-transform: capitalize;
}

.user-records .toggle-enable-status button {
    width: 80px;
}

.auto-logout-seconds input {
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100px;
}