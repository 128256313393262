.call-time-filter {
    width: 100%;
}

.call-time-filter input {
    height: 38px;
    width: 100%;
    font-size: 14px;
    padding-left: 13px;
}

.call-time-filter .rc-time-picker-clear {
    width: 30px;
    height: 30px;
    top: 8px;
}