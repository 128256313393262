.production-day-container {
}

.production-name {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.production-day-status-container{
    padding-left: 2.3rem !important;
}

.chit-record-select__control {
    height: 28px !important;
    min-height: 1px !important;
    padding: 0 !important;

}

.chit-record-select__value-container {
    height: 28px !important;
    min-height: 1px !important;
    padding: 0 !important;
    width: 100px !important;
    font-size: 13px;
}

.chit-record-select__indicators {
    width: 20px !important;
}

.chit-record-select__dropdown-indicator {
    height: 28px !important;
    min-height: 1px !important;
    padding: 0 !important;
    padding-top: 5px !important;
    font-size: 10px;
}

.chit-record-select__indicator-separator {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.chit-record-select__dropdown-indicator svg {
    width: 15px !important;
    height: 15px !important;
    margin-left: 2px !important;
}

.chit-record-select__clear-indicator {
    height: 28px !important;
    min-height: 1px !important;
}

.chit-record-select__indicator {
    height: 28px !important;
    min-height: 1px !important;
}

.chit-record-select__option {
    padding: 3px !important;
    font-size: 13px !important;
}

.chit-record-select__input{
    padding-left: 3px !important;
}

.chit-record-select__single-value {
    padding-left: 3px !important;
    font-size: 13px !important;
}

.chit-record-select__placeholder {
    padding-left: 3px !important;
}

.call-time-input {
}

.call-time-input input {
    height: 38px;
    width: 100%;
    font-size: 14px;
    padding-left: 13px;
}

.call-time-input .rc-time-picker-clear {
    width: 30px;
    height: 30px;
    top: 8px;
}

.rc-time-picker-panel {
    z-index: 10000 !important;
}

.Toastify__toast--success {
    background-color: #02BC77 !important;
}

/*Move notification bit up (default is 1rem) since react app is rendered as part of container which
  is created with play template*/
.Toastify__toast-container--top-right {
    top: 0 !important;
}

.abd{
    background-color: red;
}