.tab-content {
    padding-top: 15px;
}

.no-entries {
    text-align: center;
    padding-top: 200px;
    padding-bottom: 200px;
    font-size: 36px;
    color: #777;
}

