.production-days-table {
    margin-top: 15px;
}

.production-days-table a:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.production-days-table td {
    overflow: hidden;
}

.production-days-table td.production-days-table-date a {
    display: block;
    margin: -10em;
    padding: 10em;
}

.production-days-table .secondary-ads-names-and-links span:not(:last-child):after {
   content: ", "
}

.sync-action-btn-group {
    /*display: flex;*/
    /*position: absolute;*/
    /*bottom: 0;*/
    margin-top: 21px;
}

.sync-action-btn-group button {
    margin-left: 10px;
}

.tooltip-content {
    text-align: left;
}

.day-unit-info {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.day-unit-info:last-child  {
    border-bottom: none;
}

.production-day-qr-code-modal .modal-title {
    font-size: 14px !important;
}

.health-survey-result-details .row {
    padding-top: 5px;
    padding-bottom: 5px;
}
