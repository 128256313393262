.production-units-table {
    margin-top: 15px;
}

.production-unit-row .days .date-label {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace !important;
}

.production-unit-row .in-row-row {
    padding: 0;
    background-color: inherit !important;
}

.production-units-row-actions button {
    width: 60px;
}

