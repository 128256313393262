.chit-bulk-action-success td {
}

.chit-bulk-action-failure td {
    border-bottom: none;
}

.chit-bulk-action-failure-message td {
    border-top: none;
    font-size: 12px;
}

.chits-table-header tr {
    height: 70px;
}

.chits-table-header td {
    font-weight: bold;
}

.chits-by-production-unit .card-header {
    font-weight: bold !important;
}

.chits-table-card {
    padding: 0 !important;
}

.chits-table-header-card {
    padding-bottom: 0 !important;
}

/*Common columns*/

.chit-column-checkbox {
    width: 2% !important;
}

.chit-column-extra-id {
    width: 5%;
}

.chit-column-extra-mobile {
    width: 7%;
}
.chit-column-first-name {
    width: 9%;
}

.chit-column-last-name {
    width: 9%;
}

.chit-column-chit {
    width: 5%;
}

/*Base info*/

.chit-column-role {
    width: 14%;
}

.chit-column-costume {
    width: 7%;
}

.chit-column-status {
    width: 7% !important;
}

.chit-column-call-time {
    width: 5%;
}

.chit-column-check-in-time {
    width: 8%;
}

.chit-column-check-out-time {
    width: 8%;
}

.chit-column-unit {
    width: 10%;
}

.chit-column-day-type {
    width: 8%;
}

/*Booking Date Details*/
.chit-column-address {
    width: 20%;
}

.chit-column-entrance {
    width: 10%;
}

.chit-column-contact-name {
    width: 10%;
}

.chit-column-contact-number {
    width: 10%;
}

.chit-column-costume {
    width: 10% !important;
}


.chit-column-other-info {
    width: 15%;
}

/*Health Survey*/
.chit-column-health-survey-type {
    width: 12%;
}

.chit-column-health-survey-status {
    width: 12%;
}

.chit-column-health-survey-recorded-at {
    width: 12%;
}

.chit-column-health-survey-actions {
    width: 12%;
}

/*Documents*/
.chit-column-documents {
    width: 40%;
}

.chit-column-documents .chit-documents {
    display: flex;
}

.chit-colum-notes {
    width: 3%;
}
.chit-column-tarvel-notes {
    width: 7.8%;
}
.travel-note-info {
    background-color: white;
}

.chit-column-documents .chit-documents .chit-document {
    margin-left: 5px;
}

.chit-column-documents .chit-documents .chit-document a:hover {
    text-decoration: none !important;
}

.chit-column-documents .chit-documents .chit-document:first-child {
    margin-left: 0;
}

.document-info-tooltip .tooltip-content {
    width: 300px;
}

.document-info-tooltip > .tooltip-inner {
    max-width: 100%;
}

.document-info-tooltip .document-value {
    font-weight: bold;
}

.chits-tabs a:hover {
    text-decoration: none !important;
}

.chit-row {
    height: 50px;
}

.uve-questionnaire-details {
    margin-top: 15px;
    padding-left: 15px;
}

.uve-questionnaire-details li {
    margin-top: 10px;
}

.uve-questionnaire-details li:first-child {
    margin-top: 0;
}

.production-document-row {
    padding-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}

.production-document-row:last-child {
    border-bottom: none;
}

.production-document-row:hover {
    background-color: #eee;
}

.production-document-row a:hover {
    text-decoration: none !important;
}

.production-document-row-title {
    padding-bottom: 5px;
}

.tn-dp > input {
    height: 3em;
}

.tn-dp > input:focus {
    border: none !important;
    border-color: #02bc77 !important;
    box-shadow: 0 0 0 1px #02bc77 !important;
    border-style: none !important;
    border-radius: 0.25rem !important;
}

.tn-dp > input:hover {
    border-color: #02bc77 !important;
}

#production-chit-travel-notes {
    height: 8em;
}

/* Call Details */
.cd-header {
    width: 9%;
    text-align: center;
}

.cd-record {
    width: 9%;
    text-align: center;
}

.chit-column-response > span {
    border: 1px solid #02bc77;
    border-radius: 0.25rem;
    padding: 5px;
    font-size: 0.82rem;
}
.chit-column-response-negative > span {
    border: 1px solid var(--red);
    border-radius: 0.25rem;
    padding: 5px;
    font-size: 0.82rem;
}

.chit-column-response-review > span {
    border: 1px solid var(--warning);
    border-radius: 0.25rem;
    padding: 5px;
    font-size: 0.82rem;
    cursor: pointer;
}
.chit-column-response-yes > span {
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    padding: 5px;
    font-size: 0.82rem;
    cursor: not-allowed;
}
.chit-column-response-no > span {
    border: 1px solid var(--red);
    border-radius: 0.25rem;
    padding: 5px;
    font-size: 0.82rem;
    cursor: pointer;
}

.sms-modal-body{
  width: 320px;
}

.sms-call-input-wrapper > textarea{
width: 100%;
height: 250px;
}

