.ads-table {
    margin-top: 20px;
}

.selected-ads {
}

.selected-ads .selected-ads-content {
    margin-top: 15px !important;
}

.selected-ads .selected-ads-content .selected-ads-row {
    margin-bottom: 10px !important;
}

.selected-ads .selected-ads-content .selected-ads-row:last-child {
    margin-bottom: 0;
}

.add-ads-select {
    margin-top: 15px;
}

