.action-btn {
    color: #02BC77 !important;
}

.action-btn:hover {
    text-decoration: none !important;
    color: #fff !important;;
}

.action-danger-btn {
    color: #d9534f !important;
}

