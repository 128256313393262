html {
    scroll-behavior: smooth;
}

.table-with-clickable-row { }

.table-with-clickable-row tbody tr {
}

.table-with-clickable-row tbody tr td {
    padding: 0 !important;
    height: 100%;
    vertical-align: middle;
}

.table-with-clickable-row tbody tr td a {
    display: block !important;
    color: #4e5155;
    height: 100% !important;
}

.table-with-clickable-row tbody tr:hover {
    background-color: #eee;
    cursor: pointer;
    color: #4e5155;
}

@keyframes highlighted-row-keyframe {
    to {background-color: #fdf7e3;}
}

.highlighted-row {
    animation: highlighted-row-keyframe 5s infinite;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

a {
    color: #4e5155;
}

a:hover {
    color: #000;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.tab-content {
    background-color: #fff !important;
    padding: 15px;
    border-left: 1px solid rgba(24, 28, 33, 0.06);;
    border-right: 1px solid rgba(24, 28, 33, 0.06);;
}

.in-row-row .in-row-row-actions {
    visibility: hidden;
    text-align: right;
}

.in-row-row {
    margin: 0 !important;
}

/*.in-row-row:hover {*/
/*    background-color: #f5f5f5;*/
/*}*/

.in-row-row:hover .in-row-row-actions {
    visibility: visible;
}

.in-row-row button {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.in-row-row .in-row-row-col {
    padding: 5px !important;;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.field-error {
    color: #ce4f4b;
}

.css-1pahdxg-control{
    border: 1px solid rgba(24, 28, 33, 0.1);
    border-color: #02BC77 !important;
    box-shadow: 0 0 0 1px #02BC77 !important;
    border-style: none !important;
    border-radius: 0.25rem !important
}

.css-1pahdxg-control:hover{
    border-color: #02BC77 !important;

}