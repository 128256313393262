.d-btn{
    position: relative;
    right: 58%;
}
.p5{
    padding: 5pd;
}

button:disabled{
    cursor: not-allowed;
}