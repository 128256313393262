.main-container {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;
    padding-top: 200px;
}

.load-indicator-block .uve-load-indicator {
    margin-left: 130px !important;
    align-items: normal !important;
}

.load-indicator-block {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;
}

.display-badge{
    margin-top: 5%;
    display: none;
    text-align :center;
}

.checkbox {
    font-weight: 400;
}

.form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-control:focus {
    z-index: 2;
}

input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}