.production-records td a {
    padding: 15px;
}

.production-records td a:hover {
    text-decoration: none !important;
}

.production-records .production-days-select {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.production-days-select__value-container {
    width: 100px !important;
}

.production-days-select__option {
    padding-top: 10px !important;
    cursor: pointer !important;
}
