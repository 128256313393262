.production-external-survey-upload-document-container {
    width: 400px;
    margin-top: 10px;
}

.dzu-dropzone {
    overflow: hidden;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
}